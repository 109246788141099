import { ReactNode, useEffect, useState } from "react";
import { Flex, Stack, Avatar } from "@chakra-ui/react";
import { api } from "../../services/api";
import { Footer } from "../../components/templates/Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

interface AdminProps {
  children?: ReactNode;
}

interface Company {
  name: string;
  logo: string;
}

export function DegustTemplate({ children }: AdminProps) {
  const navigate = useNavigate();
  const [logo, setLogo] = useState<string>("");
  const [company, setCompany] = useState<Company>({} as Company);
  const query = new URLSearchParams(
    useLocation().search.replace(/\+/g, "sinal-adicao")
  );
  useEffect(() => {
    if (query) {
      api
        .get(`/clientForms/degust/token?${query}`)
        .then((response) => {
          if (response.data.id) {
            localStorage.setItem(
              `degust-${response.data.search_id}`,
              response.data.id
            );
          }
          setCompany(response.data.company);
          navigate(response.data.link);
        })
        .catch((err) => {
          navigate("/");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (company.logo) {
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [company.logo]);

  return (
    <>
      <Helmet>
        <title>Falaê - Pesquisa</title>
        <link rel="icon" href="favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="logo192.png" />
      </Helmet>
      <Flex
        flexDir="column"
        justify="space-between"
        h="100vh"
        fontFamily="Mulish"
      >
        <Flex align="center" justify="center">
          <Flex
            h="120px"
            w="100vw"
            bg="white"
            borderRadius="0% 0% 50% 50%"
            boxShadow="0px 10px 12px rgba(0,0,0,0.05)"
            justify="center"
            align="center"
          >
            <Avatar
              src={logo}
              name={company.name}
              loading="lazy"
              size="xl"
              mx="auto"
              bg="transparent"
              borderRadius="50%"
            />
          </Flex>
        </Flex>
        <Flex
          maxW="500px"
          my="5px"
          justify="center"
          mx={{ base: "25px", md: "auto" }}
        >
          <Stack spacing={5} direction="column">
            {children}
          </Stack>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}
